// @flow

import React, { Component } from "react";
import styles from "../App.module.css";
import PerfectScrollbar from 'react-perfect-scrollbar';
import MJ from "../../assets/MJ.jpg";
import buttonsCarousel from "../../assets/buttonsCarousel.png";

class TarjetaPersonaHorizontal extends Component {

    constructor(props) {
		super(props);
	}

	render() {
        
        let {mobile} = this.props;

		return (
            
            <div className={mobile?styles.personCard2Mobile:styles.personCard2}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', justifyItems: 'center', height: '100%', width: mobile?'auto':'25vw', marginTop: mobile?"20px":"0px"}}>
                    <img src={this.props.foto} style={{borderRadius: "50%", marginRight: "auto", marginLeft: "auto", marginTop: "2vw", padding: "2px", width: "200px", height: "200px", objectFit: "cover"}} alt={this.props.nombre} title={this.props.nombre}></img>
                    <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", marginTop: "5px", marginBottom: "20px"}}>
                        <p className={styles.personCardTitle} style={{textAlign: "center", backgroundColor: "#f5f9fb", borderRadius: "20px", boxShadow: "0px 0px 3px 3px #f5f9fb"}}>{this.props.nombre}</p>
                    </div>
                </div>
                
                <div className={styles.textImage}>
                    <div className={styles.textCV}>
                        {this.props.contenido}
                    </div>
                </div>
            </div>
                            
		);

	}

}

export default TarjetaPersonaHorizontal;
