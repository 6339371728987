// @flow

import React, { Component } from "react";
import banner1 from "../../assets/banner1.jpg";
import banner2 from "../../assets/banner2.jpg";
import banner3 from "../../assets/banner3.png";
import styles from "../App.module.css";
import PerfectScrollbar from 'react-perfect-scrollbar';
import puzzle from "../../assets/fichas.jpg";
import puzzle2 from "../../assets/puzzle2.png";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Banner from "./Banner.js";
import buttonsCarousel from "../../assets/buttonsCarousel.png";
import News from "./News.js";
import Footer from "./Footer.js";
import yo_peque from "../../assets/yo_peque.png";
import fichas from "../../assets/boca.png";
import bebe from "../../assets/bebe.jpeg";
import neuro from "../../assets/Neuro.jpeg";
import letras from "../../assets/bolis.jfif";
import profile from "../../assets/profile.PNG";

class Introduccion extends Component {

    constructor(props) {
        super(props);
    }

	render() {

        let {openNew, news, language, openEspecialidad, mobile} = this.props;

		return (
            <div style={{height: "90vh"}}>
                <PerfectScrollbar>
                    <div className={styles.AppBody}>
                        <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={500}
                            totalSlides={3}
                            infinite={true}
                            isPlaying={true}
                            interval={5000}
                            className={styles.carousel}
                        >
                            
                            <Slider moveThreshold={0.25} className={styles.bannerSlider}>
                                <Slide className={styles.bannerSlide} index={0}>
                                    <Banner image={banner1} title={language==0?"Aconsegueix equilibri i benestar emocional":"Consigue equilibrio y bienestar emocional"} description={language==0?"T'ajudem a adquirir les estratègies necessàries":"Te ayudamos a adquirir las estrategias necesarias"}></Banner>
                                </Slide>
                                <Slide className={styles.bannerSlide} index={1}>
                                    <Banner image={banner2} title={language==0?"Millorem el rendiment escolar i l'autoestima":"Mejoramos el rendimiento escolar y la autoestima"} description={language==0?"Som especialistes en tractar dificultats d'aprenentatge":"Somos especialistas en tratar dificultades de aprendizaje"}></Banner>
                                </Slide>
                                <Slide className={styles.bannerSlide} index={2}>
                                    <Banner image={banner3} title={language==0?"Fomentem les habilitats comunicatives":"Fomentamos las habilidades comunicativas"} description={language==0?"Tractem dificultats de la parla i del llenguatge":"Tratamos dificultades del habla y del lenguaje"}></Banner>
                                </Slide>
                            </Slider>
                            <ButtonBack className={styles.bannerBackButton}>►</ButtonBack>
                            <ButtonNext className={styles.bannerNextButton}>►</ButtonNext>
                        </CarouselProvider>

                        <div className={styles.imageTextCard}>
                            <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", paddingTop: "3vw", fontSize: "25px"}}>
                                <p className={mobile?styles.titleMobile:styles.title}>{language==0?"GAPI, un espai de confiança i qualitat":"GAPI, un espacio de confianza y calidad"}</p>
                                <div className={styles.separationLine}></div>
                            </div>
                            <p style={{marginLeft: mobile?'10vw':'20vw', marginRight:mobile?'10vw':'20vw', marginTop: "30px", fontSize: mobile?'15px':'22px', textAlign: "center"}}>{language==0?"GAPI compta amb més de 30 anys d'existència com a centre especialitzat en psicopedagogia, logopèdia, psicologia clínica i neuropsicologia.":"GAPI cuenta con más de 30 años de existencia como centro especializado en psicopedagogía, logopedia, psicología clínica y neuropsicología."}</p>
                            <p style={{marginLeft: mobile?'10vw':'20vw', marginRight:mobile?'10vw':'20vw', fontSize: mobile?'15px':'22px', textAlign: "center"}}>{language==0?"Està format per un equip de professionals qualificats amb àmplia experiència i en continua formació, per oferir la millor atenció a nens, nenes, adolescents i adults, de manera personalitzada.":"Está formado por un equipo de profesionales cualificados con amplia experiencia y en continua formación, para ofrecer la mejor atención a niños, niñas, adolescentes y adultos, de forma personalizada."}</p>
                                {mobile?
                                <div style={{marginTop: "50px"}}>
                                    <div className={styles.textImageMobile}>
                                        <div style={{width: "30vw", height: "30vw", justifyContent: "center", justifyItems: "center", cursor: "pointer"}}>
                                            <img src={profile} style={{height: "95%", width: "95%", objectFit: "cover", filter: "brightness(80%)"}} onClick={() => openEspecialidad("psico")}/>
                                            <p style={{fontSize: "3vw", position: "relative", fontWeight: "bolder", bottom: "8vw", color: "white", fontFamily: "sans-serif", textShadow: "3px 2px 3px black", width: "95%", textAlign: "center"}} onClick={() => openEspecialidad("psico")}>{language==0?"Psicologia":"Psicología"}</p>
                                        </div>
                                        <div style={{width: "30vw", height: "30vw", justifyContent: "center", justifyItems: "center", cursor: "pointer"}}>
                                            <img src={fichas} style={{height: "95%", width: "95%", objectFit: "cover", filter: "brightness(80%)"}} onClick={() => openEspecialidad("logo")}/>
                                            <p style={{fontSize: "3vw", position: "relative", fontWeight: "bolder", bottom: "8vw", color: "white", fontFamily: "sans-serif", textShadow: "3px 2px 3px black", width: "95%", textAlign: "center"}} onClick={() => openEspecialidad("logo")}>{language==0?"Logopèdia":"Logopedia"}</p>
                                        </div>
                                        <div style={{width: "30vw", height: "30vw", justifyContent: "center", justifyItems: "center", cursor: "pointer"}}>
                                            <img src={letras} style={{height: "95%", width: "95%", objectFit: "cover", filter: "brightness(80%)"}} onClick={() => openEspecialidad("psicopeda")}/>
                                            <p style={{fontSize: "3vw", position: "relative", fontWeight: "bolder", bottom: "8vw", color: "white", fontFamily: "sans-serif", textShadow: "3px 2px 3px black", width: "95%", textAlign: "center"}} onClick={() => openEspecialidad("psicopeda")}>{language==0?"Psicopedagogia":"Psicopedagogía"}</p>
                                        </div>
                                    </div>
                                    <div className={styles.textImageMobile}>
                                        
                                        <div style={{width: "30vw", height: "30vw", justifyContent: "center", justifyItems: "center", cursor: "pointer"}}>
                                            <img src={neuro} style={{height: "95%", width: "95%", objectFit: "cover", filter: "brightness(70%)"}} onClick={() => openEspecialidad("neuro")}/>
                                            <p style={{fontSize: "3vw", position: "relative", fontWeight: "bolder", bottom: "8vw", color: "white", fontFamily: "sans-serif", textShadow: "3px 2px 3px black", width: "95%", textAlign: "center"}} onClick={() => openEspecialidad("neuro")}>{language==0?"Neuropsicologia":"Neuropsicología"}</p>
                                        </div>
                                        <div style={{width: "30vw", height: "30vw", justifyContent: "center", justifyItems: "center", cursor: "pointer"}}>
                                            <img src={bebe} style={{height: "95%", width: "95%", objectFit: "cover", filter: "brightness(85%)"}} onClick={() => openEspecialidad("infancia")}/>
                                            <p style={{fontSize: "2.4vw", position: "relative", fontWeight: "bolder", bottom: "9vw", color: "white", fontFamily: "sans-serif", textShadow: "3px 2px 3px black", width: "95%", textAlign: "center"}} onClick={() => openEspecialidad("infancia")}>{language==0?"Assessorament en primera infància":"Asesoramiento en primera infancia"}</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={styles.textImage}>
                                    <div style={{width: "20vw", height: "20vw", justifyContent: "center", justifyItems: "center", cursor: "pointer"}}>
                                        <img src={profile} style={{height: "95%", width: "95%", objectFit: "cover", filter: "brightness(80%)"}} onClick={() => openEspecialidad("psico")}/>
                                        <p style={{fontSize: "2vw", position: "relative", fontWeight: "bolder", bottom: "4vw", color: "white", fontFamily: "sans-serif", textShadow: "3px 2px 3px black", width: "95%", textAlign: "center"}} onClick={() => openEspecialidad("psico")}>{language==0?"Psicologia":"Psicología"}</p>
                                    </div>
                                    <div style={{width: "20vw", height: "20vw", justifyContent: "center", justifyItems: "center", cursor: "pointer"}}>
                                        <img src={fichas} style={{height: "95%", width: "95%", objectFit: "cover", filter: "brightness(80%)"}} onClick={() => openEspecialidad("logo")}/>
                                        <p style={{fontSize: "2vw", position: "relative", fontWeight: "bolder", bottom: "4vw", color: "white", fontFamily: "sans-serif", textShadow: "3px 2px 3px black", width: "95%", textAlign: "center"}} onClick={() => openEspecialidad("logo")}>{language==0?"Logopèdia":"Logopedia"}</p>
                                    </div>
                                    <div style={{width: "20vw", height: "20vw", justifyContent: "center", justifyItems: "center", cursor: "pointer"}}>
                                        <img src={letras} style={{height: "95%", width: "95%", objectFit: "cover", filter: "brightness(80%)"}} onClick={() => openEspecialidad("psicopeda")}/>
                                        <p style={{fontSize: "2vw", position: "relative", fontWeight: "bolder", bottom: "4vw", color: "white", fontFamily: "sans-serif", textShadow: "3px 2px 3px black", width: "95%", textAlign: "center"}} onClick={() => openEspecialidad("psicopeda")}>{language==0?"Psicopedagogia":"Psicopedagogía"}</p>
                                    </div>
                                    <div style={{width: "20vw", height: "20vw", justifyContent: "center", justifyItems: "center", cursor: "pointer"}}>
                                        <img src={neuro} style={{height: "95%", width: "95%", objectFit: "cover", filter: "brightness(70%)"}} onClick={() => openEspecialidad("neuro")}/>
                                        <p style={{fontSize: "2vw", position: "relative", fontWeight: "bolder", bottom: "4vw", color: "white", fontFamily: "sans-serif", textShadow: "3px 2px 3px black", width: "95%", textAlign: "center"}} onClick={() => openEspecialidad("neuro")}>{language==0?"Neuropsicologia":"Neuropsicología"}</p>
                                    </div>
                                    <div style={{width: "20vw", height: "20vw", justifyContent: "center", justifyItems: "center", cursor: "pointer"}}>
                                        <img src={bebe} style={{height: "95%", width: "95%", objectFit: "cover", filter: "brightness(85%)"}} onClick={() => openEspecialidad("infancia")}/>
                                        <p style={{fontSize: "1.4vw", position: "relative", fontWeight: "bolder", bottom: "4.5vw", color: "white", fontFamily: "sans-serif", textShadow: "3px 2px 3px black", width: "95%", textAlign: "center"}} onClick={() => openEspecialidad("infancia")}>{language==0?"Assessorament en primera infància":"Asesoramiento en primera infancia"}</p>
                                    </div>
                                </div>
                                }
                        </div>
                        <div style={{width: "100vw", backgroundColor: "#ffffff", display: "flex", flexDirection: "row"}}>
                            <div style={{paddingTop: "0px"}}>
                                <News mobile={mobile} openNew={(id, open) => openNew(id, open)} news={news} language={language}></News>
                            </div>
                        </div>

                        <Footer language={language} top={"-16px"} bgColor={"white"}></Footer>
                        
                    </div>
                </PerfectScrollbar>
                
            </div>
		);

	}

}

export default Introduccion;
