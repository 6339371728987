// @flow

import React, { Component } from "react";
import styles from "../App.module.css";

class VisorImagenes extends Component {

    constructor(props) {
        super(props);

    }

	render() {
		
		return (
            
            <div className={styles.newsDetailBackground}>
                <div style={{display: "flex", width: "100%", height: "100%"}} onClick={() => this.props.openImg()}>
                    <img className={styles.visorImagen} src={this.props.imagen} onClick={() => this.props.openImg()}></img>
                </div>
            </div>
		);

	}

}

export default VisorImagenes;
