// @flow

import React, { Component } from "react";
import logo from "../assets/logo_nuevo.png";
import styles from "./App.module.css";
import {Button, Dropdown, Menu} from "semantic-ui-react";
import Introduccion from "./elements/Introduccion";
import Especialidades from "./elements/Especialidades";
import Us from "./elements/Us";
import Contact from "./elements/Contact";
import NewsDetail from "./elements/NewsDetail";
import NewsData from "./data/NewsData.json";
import VisorImagenes from "./elements/VisorImagenes";

class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			menuSelected: 0,
			newId: -1,
            displayNew: false,
			news: NewsData,
			language: 1,
			focusEsp: "",
			mobile: window.innerWidth < window.innerHeight,
			displayImg: false,
			imagen: null
		};
	}

	openNew(id, open) {

        this.setState({
            newId: id,
            displayNew: open
        });
    }

	viewImg(img, enable){
		this.setState({
			imagen: img,
			displayImg: enable
		});
	}

	changeLanguage(lan){
		this.setState({
			language: lan
		})
	}

	openEspecialidad(focus){
		this.setState({menuSelected: 2, focusEsp: focus});
	}

	render() {

		let {displayNew, newId, news, language, mobile, menuSelected, focusEsp, displayImg, imagen} = this.state;
		
		const options = [
			{ key: 'edit', icon: 'edit', text: 'Edit Post', value: 'edit' },
			{ key: 'delete', icon: 'delete', text: 'Remove Post', value: 'delete' },
			{ key: 'hide', icon: 'hide', text: 'Hide Post', value: 'hide' },
		  ]
		  
		return (
			<div className={styles.App}>
				
				<div className={styles.AppHeader}>
					<img src={logo} className={styles.AppLogo} alt="logo" />
					<div height={"100%"}>
						<div style={{display: "flex", flexDirection: "row-reverse", height: "50%"}}>
							<a style={{paddingLeft: "10px", paddingRight: "10px", color: language == 0 ? "white" : "black", cursor: "pointer", backgroundColor: language == 0 ? "#6995ca" : "#e8f8ff"}} onClick={()=>this.changeLanguage(0)}>
								<span style={{position: "relative", top: "25%"}}>Català</span>
								</a>
							<div style={{marginTop: "auto", marginBottom: "auto", height: "100%", width: "2px", backgroundColor: "black", opacity: 0.1}}></div>
							<a style={{paddingRight: "10px", paddingLeft: "10px", color: language == 1 ? "white" : "black", cursor: "pointer", backgroundColor: language == 1 ? "#6995ca" : "#e8f8ff"}} onClick={()=>this.changeLanguage(1)}>
								<span style={{position: "relative", top: "25%"}}>Castellano</span>
							</a>
						</div>
						<div className={styles.headerButtons}>
							{!mobile ?
							<Button.Group inverted color="blue">
								<Button onClick={() => this.setState({menuSelected: 0})} active={menuSelected == 0}>{language == 0?"Introducció":"Introducción"}</Button>
								<Button onClick={() => this.setState({menuSelected: 1})} active={menuSelected == 1} className={styles.button}>{language == 0?"Qui som":"Quienes somos"}</Button>
								<Button onClick={() => this.setState({menuSelected: 2, focusEsp: ""})} active={menuSelected == 2} className={styles.button}>{language == 0?"Especialitats":"Especialidades"}</Button>
								<Button onClick={() => this.setState({menuSelected: 3})} active={menuSelected == 3} className={styles.button}>{language == 0?"Contacte":"Contacto"}</Button>
							</Button.Group>
							:
							<Menu compact color='blue' inverted>
								<Dropdown compact text="Menú" simple item direction='left'>
									<Dropdown.Menu>
									<Dropdown.Item onClick={() => this.setState({menuSelected: 0})}>{language == 0?"Introducció":"Introducción"}</Dropdown.Item>
									<Dropdown.Item onClick={() => this.setState({menuSelected: 1})}>{language == 0?"Qui som":"Quienes somos"}</Dropdown.Item>
									<Dropdown.Item onClick={() => this.setState({menuSelected: 2, focusEsp: ""})}>{language == 0?"Especialitats":"Especialidades"}</Dropdown.Item>
									<Dropdown.Item onClick={() => this.setState({menuSelected: 3})}>{language == 0?"Contacte":"Contacto"}</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</Menu>
							}
						</div>
					</div>
				</div>

				{menuSelected == 0 ?
					<Introduccion mobile={mobile} openNew={(id, open) => this.openNew(id, open)} news={news}language={language} openEspecialidad={(focus) => this.openEspecialidad(focus)}></Introduccion> :
				menuSelected == 1 ?
					<Us openImg={(img, enable) => this.viewImg(img, enable)} mobile={mobile} language={language}></Us> :
				menuSelected == 2 ?
					<Especialidades focus={focusEsp} mobile={mobile} language={language}></Especialidades> :
				menuSelected == 3 ?
					<Contact mobile={mobile} language={language}></Contact> :
				null}

				{displayNew &&
                    <NewsDetail news={news.news.find(n => n.id == newId)} openNew={(id, open) => this.openNew(id, open)} language={language} mobile={mobile}></NewsDetail>
                }
				{displayImg &&
					<VisorImagenes openImg={() => this.viewImg(null, false)} imagen={imagen}></VisorImagenes>
				}
			</div>
		);
	}
}

export default App;
