// @flow

import React, { Component } from "react";
import { Card, Icon, Image } from 'semantic-ui-react'
import banner1 from "../../assets/banner1.jpg";
import banner2 from "../../assets/banner2.jpg";
import banner3 from "../../assets/banner3.png";
import styles from "../App.module.css";
import defaultImage from "../../assets/BackgroundGAPI.png";

class News extends Component {

    constructor(props) {
        super(props);

        this.state = {
			mostrarMas: false
		};
    }

    mostrarMasBoton() {

        this.setState({
            mostrarMas: true
        });
    }

	render() {
		
        let {openNew, news, language, mobile} = this.props;
        let {mostrarMas} = this.state;

        let elements = [];
        {
            news.news.map((item) => {
                if (elements.length < 6 || mostrarMas){
                    elements.push(
                        <Card onClick={(ev) => {openNew(parseInt(item.id), true)}}>
                            <Image src={item.imageBanner?item.imageBanner:item.image?item.image:defaultImage} wrapped ui={false} />
                            <Card.Content>
                            <Card.Header>{item.briefDescription[language]}</Card.Header>
                            <Card.Meta>
                                <span className='date'>{item.date}</span>
                            </Card.Meta>
                            <Card.Description>
                                {item.title[language]}
                            </Card.Description>
                            </Card.Content>
                        </Card>)
                }
            })
        }

		return (
            
            <div style={{width: "100%", paddingTop: "40px", paddingBottom: "40px"}}>
                <div style={{marginRight: "auto", marginLeft: "auto", width: "90%"}}>
                    <p className={mobile?styles.titleMobile:styles.title}>{language==0?"Notícies i articles":"Noticias y artículos"}</p>
                    <div className={styles.separationLine}></div>
                </div>
                <div className={styles.news}>
                    <Card.Group itemsPerRow={mobile?1:3}>
                        {elements}
                    </Card.Group>
                    {news.news.length > 6 && !mostrarMas && 
                    <div width="100%" style={{textAlign: "center", paddingTop: "30px", cursor: "pointer"}}>
                        <a onClick={() => this.mostrarMasBoton()}>{language==0?"Mostrar més":"Mostrar más"}</a>
                    </div>}
                </div>
            </div>
		);

	}

}

export default News;
