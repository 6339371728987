// @flow

import React, { Component } from "react";
import styles from "../App.module.css";
import PerfectScrollbar from 'react-perfect-scrollbar';
import fichas from "../../assets/bolis.jfif";
import neuro from "../../assets/Neuro.jpeg";
import boca from "../../assets/boca.png";
import bebe from "../../assets/bebe.jpeg";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Banner from "./Banner.js";
import PsicoClinica from "./PsicoClinica";
import Footer from "./Footer.js";

class Especialidades extends Component {

    constructor(props) {
		super(props);
	}

    componentDidMount(){
        if (this.props.focus !== ""){
            var element = document.getElementById(this.props.focus);
            if (element) element.scrollIntoView();
        }
    }

	render() {

        const logo = 
        <span>
            <p>Desde el ámbito de la logopedia, nos ocupamos de las dificultades y trastornos del habla y del lenguaje, que pueden presentarse tanto en la infancia, como en la adolescencia o en la edad adulta.</p>
            <p>La exploración inicial nos permite diseñar el programa de tratamiento a seguir, que se realiza de forma individual y en coordinación con otros profesionales que puedan estar implicados (otorrino, ortodoncista, neurólogo, etc.).</p>
            <p>Como profesionales de la logopedia centramos nuestra actuación en el tratamiento de:</p>
            <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                <br />· Dificultades de articulación (dislalias).
                <br />· Problemas de fluidez (tartamudez).
                <br />· Retraso del habla.
                <br />· Retraso o trastornos del lenguaje.
                <br />· Tratamiento de las dificultades del aprendizaje: dislexia, disgrafia, disortografía, discalculia.
                <br />· Trastornos neurológicos (TCE, accidentes vasculares cerebrales o enfermedades neurodegenerativas) que producen afectación del habla o del lenguaje (afasia, disartria, alexia, agrafia).
                <br />· Terapia miofuncional para rehabilitar la deglución atípica, la respiración oral, los malos hábitos orales, que pueden incidir en los problemas de ortodoncia.
                <br />
            </p>
        </span>
        ;

        const logoCat = 
        <span>
            <p>Des de l'àmbit de la logopèdia, ens ocupem de les dificultats i trastorns de la parla i del llenguatge, que es poden presentar tant a la infància, com a l'adolescència o a l'edat adulta.</p>
            <p>L'exploració inicial ens permet dissenyar el programa de tractament a seguir, que es realitza de manera individual i en coordinació amb altres professionals que hi puguin estar implicats (otorrí, ortodoncista, neuròleg, etc.).</p>
            <p>Com a professionals de la logopèdia centrem la nostra actuació en el tractament de:</p>
            <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                <br />· Dificultats d'articulació (dislàlies).
                <br />· Problemes de fluïdesa (tartamudesa).
                <br />· Retard de la parla.
                <br />· Retard o trastorns del llenguatge.
                <br />· Tractament de les dificultats de l'aprenentatge: dislèxia, disgrafia, disortografia, discalcúlia.
                <br />· Trastorns neurològics (TCE, accidents vasculars cerebrals o malalties neurodegeneratives) que produeixen afectació de la parla o del llenguatge (afàsia, disàrtria, alèxia, agrafia).
                <br />· Teràpia miofuncional per a rehabilitar la deglució atípica, la respiració oral, els mals hàbits orals, que poden incidir en els problemes d'ortodòncia.
                <br />
            </p>
        </span>
        ;

        const peda =
            <span>
                <p>Desde la psicopedagogía nos ocupamos de las dificultades o trastornos de aprendizaje.</p>
                <p>Se realizan tratamientos individuales, con el fin de tener una visión particular de cada caso y establecer una orientación personalizada, abordando los aspectos emocionales asociados (baja autoestima, falta de seguridad, baja tolerancia a la frustración...).</p>
                
                <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                    <br />· Dificultades o trastornos en el aprendizaje de la lectura y la escritura (Dislexia).
                    <br />· Dificultades en el razonamiento y el cálculo matemático.
                    <br />· Dificultades de razonamiento abstracto.
                    <br />· Déficit de memoria.
                    <br />· Trastorno de atención con o sin hiperactividad (TDAH).
                    <br />· Dificultades de atención - concentración.
                    <br />· Dificultades en el desarrollo motor, problemas de lateralidad, percepción, orientación espacial y temporal.
                    <br />· Dificultades para organizar y dirigir el propio estudio. Técnicas de estudio.
                    <br />
                </p>
                <p style={{fontWeight: "bold", paddingBottom: "10px", paddingTop: "20px"}}>Orientación académica y profesional:</p>   
                <p>Dirigido a estudiantes que necesitan orientación respecto a los estudios a seguir, acabada la etapa de escolarización obligatoria o durante la misma, en el caso de tener dificultades para completarla.</p>                                 
            </span>
        ;

        const pedaCat =
            <span>
                <p>Des de la psicopedagogia ens ocupem de les dificultats o els trastorns d'aprenentatge.</p>
                <p>Es fan tractaments individuals, per tal de tenir una visió particular de cada cas i establir una orientació personalitzada, abordant els aspectes emocionals associats (baixa autoestima, manca de seguretat, baixa tolerància a la frustració...).</p>
                
                <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                    <br />· Dificultats o trastorns en l'aprenentatge de la lectura i l'escriptura (Dislèxia).
                    <br />· Dificultats en el raonament i el càlcul matemàtic.
                    <br />· Dificultats de raonament abstracte.
                    <br />· Dèficit de memòria.
                    <br />· Trastorn d'atenció amb hiperactivitat o sense (TDAH).
                    <br />· Dificultats d'atenció - concentració.
                    <br />· Dificultats en el desenvolupament motor, problemes de lateralitat, percepció, orientació espacial i temporal.
                    <br />· Dificultats per a organitzar i dirigir el propi estudi. Tècniques d'estudi.
                    <br />
                </p>
                <p style={{fontWeight: "bold", paddingBottom: "10px", paddingTop: "20px"}}>Orientació acadèmica i professional:</p>   
                <p>Dirigit a estudiants que necessiten orientació respecte als estudis a seguir, acabada l'etapa d'escolarització obligatòria o durant aquesta, en cas de tenir dificultats per a completar-la.</p>                                 
            </span>
        ;

        const neuroCas = 
            <span>
                <p>La neuropsicologia es la especialidad que estudia la relación entre el cerebro y la conducta. Una afectación funcional o estructural del Sistema nervioso central, puede producir una alteración de las funciones cognitivas, superiores: </p>
                <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                    · Atención.
                    <br />· Velocidad de procesamiento.
                    <br />· Memoria.
                    <br />· Lenguaje.
                    <br />· Gnosias.
                    <br />· Funciones visoperceptivas y visoconstructivas.
                    <br />· Praxias y funciones ejecutivas.
                    <br />· Afectaciones conductuales o emocionales. 
                    <br />
                    </p>
                <p>La neuropsicologia se encargaría del diagnóstico y posterior tratamiento de las funciones alteradas.</p>
                <p>Dichas alteraciones pueden deberse a un <b>Traumatismo Craneoencefálico</b>, <b>ictus</b>, <b>tumor cerebral</b> o a enfermedades neurodegenerativas como la <b>Enfermedad de Alzheimer</b>, <b>Parkinson</b>, o  <b>esclerosis múltiple</b>.</p>
                <p><b>La neuropsicología infantil</b> estudiaría la relación entre la conducta y el Sistema nervioso en desarrollo. Se encargaría de la valoración y tratamiento de las alteraciones producidas por Daño Cerebral Sobrevenido y especialmente, de los trastornos del desarrollo: Trastorno por Déficit de atención/hiperactividad, Trastornos especificos del aprendizaje, Discapacidad intelectual, Trastornos del espectro autista.</p>
                                            
            </span>
        ;

        const neuroCat = 
            <span>
                <p>La neuropsicologia és l'especialitat que estudia la relació entre el cervell i la conducta. Una afectació funcional o estructural del sistema nerviós central, pot produir una alteració de les funcions cognitives, superiors:</p>
                <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                    · Atenció.
                    <br />· Velocitat de processament.
                    <br />· Memòria.
                    <br />· Llenguatge.
                    <br />· Gnòsies.
                    <br />· Funcions visoperceptives i visoconstructives.
                    <br />· Praxies i funcions executives.
                    <br />· Afectacions conductuals o emocionals. 
                    <br />
                    </p>
                <p>La neuropsicologia s'encarregaria del diagnòstic i posterior tractament de les funcions alterades.</p>
                <p>Aquestes alteracions poden ser degudes a un <b>Traumatisme Craneoencefàlic</b>, <b>ictus</b>, <b>tumor cerebral</b> o malalties neurodegeneratives com la <b>Malaltia d'Alzheimer</b>, <b>Parkinson</b>, o <b>esclerosi múltiple</b>.</p>
                <p><b>La neuropsicologia infantil</b> estudiaria la relació entre la conducta i el sistema nerviós en desenvolupament. S'encarregaria de la valoració i el tractament de les alteracions produïdes per Dany Cerebral Sobrevingut i especialment, dels trastorns del desenvolupament: Trastorn per Dèficit d'atenció/hiperactivitat, Trastorns específics de l'aprenentatge, Discapacitat intel·lectual, Trastorns de l'espectre autista.</p>
                                            
            </span>
        ;

        const inf = 
            <span>
                <p>Asesoramos a familias con niños hasta los 6 años, recogiendo sus dudas e inquietudes respecto a la crianza de su hijo o hija, en una etapa clave del desarrollo.</p>
                <p>Analizamos, mediante una entrevista, las necesidades de cada familia y ofrecemos orientaciones y pautas personalizadas.</p>
                <p>Las orientaciones pueden estar relacionadas con áreas como:</p>
                
                <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                    <br />· El hábito de dormir.
                    <br />· Dificultades relacionadas con la alimentación.
                    <br />· Cambios familiares: nacimiento de un hermanito/a, cambios de domicilio, separación de pareja...
                    <br />· Situaciones de duelo.
                    <br />· Abordaje de rabietas.
                    <br />
                </p>
            </span>
        ;

        const infCat = 
            <span>
                <p>Assessorem a famílies amb infants fins als 6 anys, recollint els seus dubtes i inquietuds respecte a la criança del seu fill o filla, en una etapa clau del desenvolupament.</p>
                <p>Analitzem, mitjançant una entrevista, les necessitats de cada família i oferim orientacions i pautes personalitzades.</p>
                <p>Les orientacions poden estar relacionades amb àrees com:</p>
                
                <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                    <br />· L’hàbit de dormir.
                    <br />· Dificultats relacionades amb l’alimentació.
                    <br />· Canvis familiars: naixement d’un germanet/a, canvis de domicili, separació de parella...
                    <br />· Situacions de dol.
                    <br />· Abordatge de rebequeries.
                    <br />
                </p>
            </span>
        ;

        let {mobile, language} = this.props;

		return (
            <div className={styles.backgroundUs}>
                <PerfectScrollbar>
                    <div className={styles.AppBodyEsp}>
                        <div>
                            <PsicoClinica mobile={mobile} language={language}></PsicoClinica>
                            <div id="logo" className={styles.bloqueEspecialidad}>
                                <div style={{flexDirection: mobile?"column":"row", display: "flex"}}>
                                    {mobile && <img src={boca} style={{height: mobile?"45vh":"90vh", width: mobile?"100%":"50%", objectFit: "cover"}}></img>}
                                    <div className={mobile?styles.textoEspecialidadMobile:styles.textoEspecialidad}>
                                        <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", marginBottom: "3vw"}}>
                                            <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.title}>{language==0?"Logopèdia":"Logopedia"}</p>
                                            <div style={{marginRight: "auto", marginLeft: "auto"}} className={styles.separationLinePersonCard}></div>
                                        </div>
                                        {language==0?logoCat:logo}
                                    </div>
                                    {!mobile && <img src={boca} style={{height: mobile?"45vh":"90vh", width: mobile?"100%":"50%", objectFit: "cover"}}></img>}
                                </div>
                            </div>
                            <div id="psicopeda" className={styles.bloqueEspecialidad}>
                                <div style={{flexDirection: mobile?"column":"row", display: "flex"}}>
                                    <img src={fichas} style={{height: mobile?"45vh":"90vh", width: mobile?"100%":"50%", objectFit: "cover"}}></img>
                                    <div className={mobile?styles.textoEspecialidadMobile:styles.textoEspecialidad}>
                                        <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", marginBottom: "3vw"}}>
                                            <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.title}>{language==0?"Psicopedagogia":"Psicopedagogía"}</p>
                                            <div style={{marginRight: "auto", marginLeft: "auto"}} className={styles.separationLinePersonCard}></div>
                                        </div>
                                        {language==0?pedaCat:peda}
                                    </div>
                                </div>
                            </div>
                            <div id="neuro" className={styles.bloqueEspecialidad}>
                                <div style={{flexDirection: mobile?"column":"row", display: "flex"}}>
                                    {mobile && <img src={neuro} style={{height: mobile?"45vh":"90vh", width: mobile?"100%":"50%", objectFit: "cover"}}></img>}
                                    <div className={mobile?styles.textoEspecialidadMobile:styles.textoEspecialidad}>
                                        <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", marginBottom: "3vw"}}>
                                            <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.title}>{language==0?"Neuropsicologia":"Neuropsicología"}</p>
                                            <div style={{marginRight: "auto", marginLeft: "auto"}} className={styles.separationLinePersonCard}></div>
                                        </div>
                                        {language==0?neuroCat:neuroCas}
                                        
                                    </div>
                                    {!mobile && <img src={neuro} style={{height: mobile?"45vh":"90vh", width: mobile?"100%":"50%", objectFit: "cover"}}></img>}
                                </div>
                            </div>
                            <div id="infancia" className={styles.bloqueEspecialidad}>
                                <div style={{flexDirection: mobile?"column":"row", display: "flex"}}>
                                    <img src={bebe} style={{height: mobile?"45vh":"90vh", width: mobile?"100%":"50%", objectFit: "cover"}}></img>
                                    <div className={mobile?styles.textoEspecialidadMobile:styles.textoEspecialidad}>
                                        <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", marginBottom: "3vw"}}>
                                            <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.title}>{language==0?"Assessorament en primera infància":"Asesoramiento en primera infancia"}</p>
                                            <div style={{marginRight: "auto", marginLeft: "auto"}} className={styles.separationLinePersonCard}></div>
                                        </div>
                                        {language==0?infCat:inf}
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{width: "100vw", backgroundColor: "#e8f8ff"}}>
                            <p style={{height: "auto", textAlign: "center", paddingBottom: "10px", paddingTop: "10px"}}>{language==0?"Gabinet de Psicologia GAPI • Pg. de Maragall, 70, 08041 Barcelona • T. 93 349 29 54 - M. 675 73 00 15 • gapi@gapipsicolegs.com":"Gabinete de Psicología GAPI • Pg. de Maragall, 70, 08041 Barcelona • T. 93 349 29 54 - M. 675 73 00 15 • gapi@gapipsicolegs.com"}</p>
                            </div> */}
                            <Footer language={language} padding={"15px"} bgColor={"white"}></Footer>
                        </div>
                    </div>
                </PerfectScrollbar>
            </div>
		);

	}

}

export default Especialidades;
